body {
  background-color: #191b1c;
}
.menuBtn {
  margin-left: 5px;
  padding: 9px;
}

.namedLinkContainer:first-child {
  margin-top: -15px;
}

.namedLinkContainer:last-child {
  margin-bottom: -15px;
}

.namedLinkContainer {
  transition: background-color ease 0.2s;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -20px;
  align-items: center;
  display: flex;
}

.createdItemText {
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.namedLinkContainer:hover {
  cursor: pointer;
  background-color: rgba(80, 80, 80, 0.904);
}

.linkActionBtn {
  margin-top: 1rem;
  margin-left: 10px;
}

.focused {
  background-color: rgba(80, 80, 80, 0.904);
}

.sideBarToggle {
  color: rgb(61, 61, 61);
  transition: color ease 0.1s;
}

.sideBarToggle:hover {
  color: rgb(160, 160, 160);
}

.pro-item-content {
  overflow: visible !important;
}
#documentation > div > span.pro-arrow-wrapper {
  display: none;
}
#adminLabel > div > span.pro-arrow-wrapper {
  display: none;
}
body {
  overflow-y: hidden;
}

.empty-links > .popper-element {
  display: none;
}

iframe {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.dataInput,
.dataInput:focus {
  color: #d8d8d8;
}

.createdItemText {
  width: 90%;
}